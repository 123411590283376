import './Header.css'
import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Sidebar, Container, FormInput, Grid, Image, Icon } from 'semantic-ui-react';
import { logout as logoututil } from '../logoututil'

const Header = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    const logout = () => {
      logoututil(oktaAuth)
    }
  
    useEffect(() => {
      if (!authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        oktaAuth.getUser().then((info) => {
          setUserInfo(info);
        });
      }
    }, [authState, oktaAuth]); // Update if authState changes

    return (
        <div className="Header">
          <div className="Header-Logo">
            <img style={{ height: 40 }} src="/img/HData-Logo-Dark.svg" />
          </div>
          <div className="Header-Filler"></div>
          <div className="Header-Greeting">
            Hello {userInfo ? userInfo.name : ''}
            <span title="Logout" style={{ cursor: 'pointer', paddingLeft: 20 }} onClick={logout}><img style={{ height: 20 }} src="/img/icon-signout.svg" /></span>
          </div>
        </div>
    )
}

export default Header
