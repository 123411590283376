import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import config from './config';

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode } = config.oidc;
    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo: '/img/HData-Logo-Dark.svg',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to HData',
        },
      },
      authParams: {
        issuer,
        scopes,
      },
      useInteractionCodeFlow: useInteractionCode,
    })

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        // console.log(res); // log auth info
        oktaAuth.handleLoginRedirect(res.tokens)
      },
      (err) => {
        console.error('widget.renderEl Error')
        console.error(err)
      },
    )

    // Note: Can't distinguish CORS error from other network errors
    const isCorsError = (err) => (err.name === 'AuthApiError' && !err.statusCode)

    widget.on('afterError', (_context, error) => {
      console.error('widget.on afterError')
      if (isCorsError(error)) {
        console.error('Possible CORS error with OKTA - please add origin to list of trusted OKTA origins')
      }
      console.error(error)
    })

    return () => widget.remove()
  }, [oktaAuth])

  return (
    <div>
      <div ref={widgetRef} />
    </div>
  )
}

export default Login
