import './MainPage.css'
import Header from './components/Header';
import Body from './components/Body'
import { Sidebar, Container, FormInput, Grid, Image } from 'semantic-ui-react';


function App() {
  return (
    <Container>
      <Grid>
        <Grid.Row className="MainPage-Header">
          <Grid.Column>
            <Header />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Body />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </Container>
  );
}

export default App;

