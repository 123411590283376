const CLIENT_ID = process.env.CLIENT_ID || '0oapw5yvkyYsZVFnC5d6';
const ISSUER = process.env.ISSUER || 'https://dev-86402953.okta.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'c_iris_company_id'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: false
  },
  redirectUrls: {
    dataHub: 'https://hdata.idaciti.com/ssologin'
  },
  apis: {
    baseUrl: 'https://api.fercpro.us'
  }
}
