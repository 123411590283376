import './SolutionItem.css'
import { Container, Grid, Image, Button } from 'semantic-ui-react';

const SolutionItem = ({ title, description, iconUrl, redirectUrl, buttonText, errorMessage }) => {
    const onClick = () => {
        if (!redirectUrl) { return; }
        window.location = redirectUrl
    }

    return (
        <div className="Solution-Item">
            <img className="SolutionItem-Logo" src={iconUrl} />
            <h1 className="SolutionItem-Title">{title}</h1>
            <p className="SolutionItem-Description">
                {description}
            </p>
            <div>
                { errorMessage ? (
                    <button className="btn SolutionItem-Button-Error">{errorMessage}</button>
                ) : ( 
                    <button className="btn SolutionItem-Button" onClick={onClick}>{buttonText}</button>
                )}
            </div>
        </div>
    )
}

export default SolutionItem
