import LoginPage from './LoginPage'
import MainPage from './MainPage'
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from './config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
  const customAuthHandler = () => {
    history.push('/login')
  }

  // console.log(oktaAuth)

  return (
    <Security 
      oktaAuth={oktaAuth} 
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Redirect path='/' to='/app' exact />
        <Route path='/login' exact component={LoginPage} />
        <Route path="/login/callback" component={LoginCallback} />
        <SecureRoute path='/app' exact component={MainPage} />
      </Switch>
    </Security>
  );
}

export default App
