const logout = async (oktaAuth) => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err) && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
        console.error('Possible CORS error with OKTA - please add origin to list of trusted OKTA origins')
      }
      
      throw err;
    }
}

// Note: Can't distinguish CORS error from other network errors
const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');

export { logout, isCorsError }