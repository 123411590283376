import './Body.css'
import { useOktaAuth } from '@okta/okta-react';
import { Container, Grid, Image } from 'semantic-ui-react';
import SolutionItem from './SolutionItem';
import Config from '../config'
import { useState, useEffect } from 'react';

function handleErrors(response) {
    if (!response.ok) {
        console.error(response)
        throw Error(response.statusText);
    }
    return response;
}

const Body = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [irisUrl, setIrisUrl] = useState('');
    const { authState } = useOktaAuth();

    useEffect(() => {
        fetch(Config.apis.baseUrl + "/integrations/iriscarbon/url", {
                headers: {
                    'Authorization': 'Bearer ' + authState.accessToken.accessToken
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setIrisUrl(result.url);
                },
                (error) => {
                    console.error('error connecting to filing')
                    console.error(error)
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    return (
        <div className="Body">
            <div className="Body-Content">
                <Grid columns={2} stretched stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <SolutionItem
                                title='IRIS CARBON® Filing Solution'
                                description='Create, modify, and maintain FERC reports in a shared digital environment.'
                                buttonText={isLoaded ? 'Your Filings' : 'Loading...' }
                                redirectUrl={irisUrl}
                                errorMessage={error ? 'Unable to connect to Filing at this time' : '' }
                                iconUrl='img/icon-filing-solution.svg'
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <SolutionItem
                                title='Data Hub'
                                description='Access, interact with, and use clean, normalized, and comparable FERC data.'
                                buttonText='Access Data Hub'
                                redirectUrl={Config.redirectUrls.dataHub}
                                iconUrl='img/icon-data-hub.svg' />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    )
}

export default Body
